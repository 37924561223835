
import {Component, Vue, Prop} from 'vue-property-decorator';
import GPCFooter from "@/components/GPCFooter.vue";

@Component({
    name: "HowToMint",
    components: {GPCFooter}
})
export default class HowToMint extends Vue {
    checked: any = {};

    check(indexl:number, indexi:number) {
        const i = indexl.toString() + indexi.toString();
        this.$set(this.checked, i, !this.checked[i]);
        localStorage.setItem('checked', JSON.stringify(this.checked));
    }

    beforeMount() {
        const checked:string | null = localStorage.getItem('checked');

        if (checked) {
            this.checked = JSON.parse(checked);
        }
    }
}
